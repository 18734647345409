import React, { createContext, useContext, ReactNode } from 'react'
import { useLocalStorage } from '../hooks/useLocalStorage'
interface StatusContextType {
  status: number
  updateStatus: (newStatus: number) => void
}
const StatusContext = createContext<StatusContextType | undefined>(undefined)
export const StatusProvider = ({ children }: { children: ReactNode }) => {
  const [status, setStatus] = useLocalStorage('subscriptionStatus', 1)
  const updateStatus = (newStatus: number) => {
    setStatus(newStatus)
  }
  return (
    <StatusContext.Provider value={{ status, updateStatus }}>
      {children}
    </StatusContext.Provider>
  )
}
export const useStatus = (): StatusContextType => {
  const context = useContext(StatusContext)
  if (!context) {
    throw new Error('useStatus must be used within a StatusProvider')
  }
  return context
}
