import React, { Component, Suspense } from 'react'
import { HashRouter, Route, Routes } from 'react-router-dom'
import { CSpinner } from '@coreui/react-pro'
import './scss/style.scss'
import Login from './views/login/Login'
import { AuthProvider } from './hooks/useAuth'
import { ProtectedRoute } from './components/ProtectedRoute'
import { ToastProvider } from './hooks/useToast'
import { UriPathsProvider } from './context/url-paths'
import { UniqueIdProvider } from './context/unique-id'
import { AlertProvider } from './context/alert-context'
import { StatusProvider } from './context/StatusContext'
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))
class App extends Component {
  render(): JSX.Element {
    return (
      <HashRouter>
        <Suspense fallback={<CSpinner color="primary" />}>
          <AuthProvider>
            <StatusProvider>
              <UriPathsProvider>
                <UniqueIdProvider>
                  <AlertProvider>
                    <ToastProvider>
                      <Routes>
                        <Route
                          path="*"
                          element={
                            <ProtectedRoute>
                              <DefaultLayout />
                            </ProtectedRoute>
                          }
                        />
                        <Route path="/login" element={<Login />} />
                      </Routes>
                    </ToastProvider>
                  </AlertProvider>
                </UniqueIdProvider>
              </UriPathsProvider>
            </StatusProvider>
          </AuthProvider>
        </Suspense>
      </HashRouter>
    )
  }
}

export default App
