import { useState, ChangeEvent, FormEvent, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser, faLock } from '@fortawesome/free-solid-svg-icons'
import { CLoadingButton } from '@coreui/react-pro'
import { useAuth } from '../../hooks/useAuth'
import QLlogo from '../../assets/images/ql-full-logo.webp'
import { useNavigate } from 'react-router-dom'
function Login() {
  const navigate = useNavigate()
  const { login } = useAuth()
  const [loading, setLoading] = useState<boolean>(false)
  const [username, setUsername] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const [error, setError] = useState<string>('')
  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    setError('')

    if (!username || !password) {
      setError('The username and password is required.')
      return
    }
    setLoading(true)
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/login`, {
        method: 'POST',
        body: JSON.stringify({ username, password, device_type: 'web' }),
      })

      if (response.ok) {
        const data = JSON.parse(await response.text())
        login(data)
      } else {
        const json = await response.json()
        setError(json.message || response.statusText)
      }
      setLoading(false)
    } catch (error: any) {
      setError(error)
      setLoading(false)
    }
  }

  const handleUsernameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setUsername(event.target.value)
  }

  const handlePasswordChange = (event: ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value)
  }

  return (
    <div className="container-fluid min-vh-100 d-flex align-items-center justify-content-center bg-light">
      <div
        className="row w-100 justify-content-center"
        style={{ maxHeight: '100%' }}
      >
        <div className="col-md-4 bg-white">
          <form
            className="row flex-column p-5 gx-3 gy-2"
            onSubmit={handleSubmit}
          >
            <div className="d-flex justify-content-between align-items-center">
              <h1>Login</h1>
              <img src={QLlogo} width={'100px'} height={'40px'} />
            </div>
            <p>Sign In to your account</p>
            {error && (
              <div className="alert alert-danger" role="alert">
                {error}
              </div>
            )}
            <div className="col my-2">
              <label
                className="visually-hidden"
                htmlFor="specificSizeInputGroupUsername"
              >
                Username
              </label>
              <div className="input-group">
                <span className="input-group-text">
                  <FontAwesomeIcon icon={faUser} />
                </span>
                <input
                  type="text"
                  className="form-control"
                  id="specificSizeInputGroupUsername"
                  placeholder="Email"
                  value={username}
                  onChange={handleUsernameChange}
                />
              </div>
            </div>
            <div className="col my-2">
              <label
                className="visually-hidden"
                htmlFor="specificSizeInputGroupPassword"
              >
                Password
              </label>
              <div className="input-group">
                <span className="input-group-text">
                  <FontAwesomeIcon icon={faLock} />
                </span>
                <input
                  type="password"
                  className="form-control"
                  id="specificSizeInputGroupPassword"
                  placeholder="Password"
                  value={password}
                  onChange={handlePasswordChange}
                />
              </div>
            </div>
            <div className="col my-2 d-flex align-items-center justify-content-between">
              <CLoadingButton
                color="primary"
                className="px-4"
                loading={loading}
                disabledOnLoading
                type="submit"
              >
                Login
              </CLoadingButton>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default Login
