import SuccessIcon from '../../icons/alert-message/success-icon'
import WarningIcon from '../../icons/alert-message/warning-icon'
import { AlertTitle, Snackbar } from '@mui/material'
import Alert, { AlertProps } from '@mui/material/Alert'
import {
  SyntheticEvent,
  forwardRef,
  memo,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react'

interface AlertMessageProps {
  message?: string
  title?: string
  timeout?: number
  severity: AlertProps['severity']
  id?: string
}

export interface AlertMessageRef {
  showAlert: (arg: AlertMessageProps) => void
}

const AlertMessage = forwardRef<AlertMessageRef, Omit<AlertProps, 'severity'>>(
  function AlertMessage(props, ref) {
    const { variant = 'filled', ...rest } = props
    const [alerts, setAlerts] = useState<AlertMessageProps[]>([])

    useEffect(() => {
      const timeoutIds = alerts.map((alert) =>
        setTimeout(() => {
          closeAlert(alert.id)
        }, alert.timeout),
      )

      return () => timeoutIds.forEach((id) => clearTimeout(id))
    }, [alerts])

    const closeAlert = (id?: string) => {
      if (id)
        setAlerts((prevAlerts) => prevAlerts.filter((alert) => alert.id !== id))
    }

    useImperativeHandle(
      ref,
      () => ({
        showAlert({
          message = '',
          title = '',
          timeout = 3000,
          severity = 'success',
        }) {
          const id = Math.random().toString(36).substring(2, 15)

          setAlerts((prevAlerts) => [
            ...prevAlerts,
            { id, message, title, timeout, severity },
          ])
        },
      }),
      [],
    )

    return (
      <>
        {alerts.map((alert, index) => (
          <Snackbar
            key={alert.id}
            open={true}
            autoHideDuration={alert.timeout}
            onClose={(event: SyntheticEvent | Event, reason: string) => {
              if (reason === 'timeout') {
                closeAlert(alert.id)
              }
            }}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            style={{ marginTop: index * 72 }}
          >
            <Alert
              onClose={(event?: SyntheticEvent | Event, reason?: string) => {
                if (reason === 'timeout') {
                  closeAlert(alert.id)
                }
              }}
              severity={alert.severity}
              variant={variant}
              icon={
                alert.severity === 'error' || alert.severity === 'warning' ? (
                  <WarningIcon size={40} />
                ) : (
                  <SuccessIcon size={40} />
                )
              }
              {...rest}
              sx={{
                width: '400px',
                background: '#FFFFFF',
                color: '#4A4A4A',
                borderLeft: `4px solid ${
                  alert.severity === 'error' || alert.severity === 'warning'
                    ? '#FFCC00'
                    : '#28A745'
                }`,
                marginTop: index * 2,
                ...(rest.sx ?? {}),
              }}
            >
              {alert.title ? (
                <AlertTitle sx={{ color: '#2D2D2D' }}>
                  {' '}
                  {alert.title}
                </AlertTitle>
              ) : null}
              {alert.message}
            </Alert>
          </Snackbar>
        ))}
      </>
    )
  },
)

export default memo(AlertMessage)
