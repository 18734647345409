export const enum ENV {
  DEV = 'dev',
  QA = 'qa',
  PROD = 'prod',
}
export const SEARCH_DEBOUNCE_TIME = 700
export const OPEN_URL_DELAY = 1000
const QL_ENV = (process.env.REACT_APP_ENV ?? 'dev') as ENV
const config: any = {
  vehicle_admin: {
    ql_env: QL_ENV,
    media_url:
      process.env.REACT_APP_MEDIA_URL ??
      'https://qlv-media-dev.qatarliving.com',
    base_url:
      process.env.REACT_APP_BASE_URL ?? 'https://qlvn-be-as.azurewebsites.net',
    generate_signed_url:
      process.env.REACT_APP_GENERATE_SIGNED_URL ??
      'https://bo-dev.qatarliving.com/vehicles/generateSignedURL',
    ql_url: process.env.REACT_APP_QL_URL ?? 'https://qlv-dev.qatarliving.com',
  },
  property_admin: {
    ql_env: QL_ENV,
    base_url:
      process.env.REACT_APP_BASE_QLP_URL ??
      'https://qlpbackenddev.azurewebsites.net',
    images_cdn_url:
      process.env.REACT_APP_CDN_BASE_QLP_URL ??
      'https://qlpnext-dev-eqhcdvg7dbhbcrfr.a03.azurefd.net/',
    qlp_url: process.env.REACT_APP_QLP_URL ?? 'https://qlp-dev.qatarliving.com',
  },
}

export const getConfig = () => {
  const selectedVerticle = localStorage.getItem('selectedVerticle') as string
  return config[JSON.parse(selectedVerticle)]
}
