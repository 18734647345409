import AlertMessage, { AlertMessageRef } from '../components/alert-message'
import { AlertColor } from '@mui/material'

import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useRef,
} from 'react'

type AlertContextType = (
  // eslint-disable-next-line no-unused-vars
  message: string,
  // eslint-disable-next-line no-unused-vars
  title: string,
  // eslint-disable-next-line no-unused-vars
  type: AlertColor,
) => void

const AlertContext = createContext<AlertContextType | null>(null)

export const useAlert = () => {
  const context = useContext(AlertContext)

  if (!context) {
    throw new Error('useAlert must be used within an AlertProvider')
  }
  return context
}

interface AlertProviderProps {
  children: ReactNode
}

export const AlertProvider: React.FC<AlertProviderProps> = ({ children }) => {
  const alertRef = useRef<AlertMessageRef | null>(null)

  const showAlertMsg = useCallback(
    (message: string, title: string, type: AlertColor) => {
      alertRef.current?.showAlert({
        severity: type,
        message: message,
        title: title,
      })
    },
    [],
  )

  return (
    <AlertContext.Provider value={showAlertMsg}>
      {children}
      <AlertMessage ref={alertRef} />
    </AlertContext.Provider>
  )
}
